<template>
  <!-- eslint-disable -->
  <v-data-table
    :headers="headers"
    :items="logs"
    sort-by="name"
    class="data-table elevation-1 mt-16 mt-md-3"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>After Action Reports</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-btn color="primary" dark class="mb-2" to="/afteractionReport_add">
          Add New Report
        </v-btn>
      </v-toolbar>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon class="pa-3" @click="editItem(item)">$edit</v-icon>
      <v-icon class="pa-3" @click="deleteItem(item)">$del</v-icon>
    </template>
  </v-data-table>
</template>

<style lang="scss" scoped>
@import "../scss/site.scss";

/* 0 media q */

@media (min-width: 0px) {
  .data-table {
    width: 96%;
    margin: 0 auto;
  }
  /*   .add-to-roster {
    height: 500px;
  } */
}

/* 768 media q */
@media (min-width: 768px) {
}

/* 1025 media q */
@media (min-width: 1025px) {
}

/* 2048 media q */
@media (min-width: 2048px) {
}
</style>

<script>
/* eslint-disable */
import { bus_common } from "../mixins/bus_common";
import banner from "./banner.vue";
export default {
  name: "afteraction_reports",
  components: {
    banner,
  },
  mixins: [bus_common],
  //aa data
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Officer",
        align: "left",
        sortable: true,
        value: "reporting_officer",
      },
      {
        text: "Specialty",
        align: "left",
        sortable: true,
        value: "select_specialty",
      },
      { text: "Date", align: "left", sortable: true, value: "aa_date" },
      { text: "Case#", align: "left", sortable: true, value: "case_num" },
      { text: "Time", align: "left", sortable: true, value: "aa_time" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    // logs: [
    //   {
    //     afteraction_name: "Smith",
    //     afteraction_specialty: "Explosive Breaching AARs",
    //     afteraction_date: "01/01/2024",
    //     afteraction_casenum: "24-00023",
    //     afteraction_time: "13:00",
    //   },
    //   {
    //     afteraction_name: "Jones",
    //     afteraction_specialty: "Sniper AARs",
    //     afteraction_date: "03/01/2024",
    //     afteraction_casenum: "24-00024",
    //     afteraction_time: "09:00",
    //   },
    //   {
    //     afteraction_name: "Barnes",
    //     afteraction_specialty: "Ropes AArmRs",
    //     afteraction_date: "06/01/2024",
    //     afteraction_casenum: "24-00025",
    //     afteraction_time: "23:00",
    //   },
    // ],
    logs: [],
    editedIndex: -1,

    /* editItems */
    editedItem: {
      officer: "",
      specialty: "",
      date: "",
      casenum: "",
      time: "",
    },
    defaultItem: {
      officer: "",
      specialty: "",
      date: "",
      casenum: "",
      time: "",
    },

    formIn: {
      officer: "",
      specialty: "",
      date: "",
      casenum: "",
      time: "",
    },

    // afteraction_names: [
    //   "Autry",
    //   "Baehr",
    //   "Butler",
    //   "Chang",
    //   "Christensen, B",
    //   "Clifford",
    //   "Donigan",
    //   "Dougherty",
    //   "Duralde",
    //   "Espinoza, A",
    //   "Hakin",
    //   "Hanna",
    //   "Hendrix",
    //   "Hogan",
    //   "Hoyt",
    //   "Kent",
    //   "Kerfoot",
    //   "Lancaster",
    //   "Lopey",
    //   "Mathiowetz",
    //   "Meseberg",
    //   "Perham, J",
    //   "Robles",
    //   "Rousseau",
    //   "Schaur",
    //   "Schmidt",
    //   "Smith",
    //   "Sotelo",
    //   "Struffert",
    //   "Tom",
    //   "Tone",
    //   "Waasdorp",
    //   "Williams",
    //   "Ziese",
    // ],

    /* STOPPED HERE - NEED TO FINISH CHANGING FIELD NAMEformTitleS, ETC FOR ADD FORM */

    afteraction_specialties: [
      "Explosive Breaching AARs",
      "Sniper AARs",
      "Ropes AArmRs",
      "Team training AARs",
      "Mission AARs",
    ],
    afteraction_dates: [],
    afteraction_casenums: [],
    afteraction_times: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Roster/Assignment" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {},

  methods: {
    editItem(item) {
      // this.editedIndex = this.logs.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      // this.dialog = true;
      this.$router.push({
        name: "afteractionReport_add",
        params: { id: item.aft_act_repId },
      });

      // name: 'detail', // Replace 'detail' with your route name
      //   params: {
      //     id: this.itemId
      //   }
    },
    // initialize() {
    //   this.logs = [
    //     {
    //       name: "Autry",
    //       specialty: "Ropes AArmRs",
    //       date: "01/15/2024",
    //       casenum: "24-1002",
    //       time: "13:00",
    //     },
    //     {
    //       name: "Robles",
    //       specialty: "Explosive Breaching AARs",
    //       date: "01/15/2024",
    //       casenum: "24-1003",
    //       time: "09:00",
    //     },
    //     {
    //       name: "Schaur",
    //       specialty: "Team training AARs",
    //       date: "03/30/2024",
    //       casenum: "24-1024",
    //       time: "23:00",
    //     },
    //     {
    //       name: "Meseburg",
    //       specialty: "Mission AARs",
    //       date: "05/01/2024",
    //       casenum: "24-1063",
    //       time: "06:00",
    //     },
    //     {
    //       name: "Chang",
    //       specialty: "Ropes AArmRs",
    //       date: "08/15/2024",
    //       casenum: "24-1084",
    //       time: "07:00",
    //     },
    //     {
    //       name: "Espinoza, A",
    //       specialty: "Ropes AArmRs",
    //       date: "11/30/2024",
    //       casenum: "24-1123",
    //       time: "05:00",
    //     },
    //   ];
    // },

    deleteItem(item) {
      this.editedIndex = this.logs.indexOf(item);
      //this.editedItem = Object.assign({}, item);
      //this.dialogDelete = true;
      this.logs.splice(this.editedIndex, 1);
    },

    deleteItemConfirm() {
      this.logs.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      /* if (this.editedIndex > -1) {
        Object.assign(this.fullroster[this.editedIndex], this.editedItem);
      } else { */
      this.logs.push(this.formIn);
      /* } */
      this.close();
    },

    message1() {
      alert("This button does nothing yet.");
    },
    get_aft_act_reps() {
      this.show_loader(true);
      var formIn = {};
      formIn.email = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;

      formIn.apiname = "get_aft_act_reps";
      var self = this;

      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          self.logs = self.mno(data);
          for (let index = 0; index < self.logs.length; index++) {
            const element = self.logs[index];
            element.aa_date = self.format_date_us(element.aa_date);
          }
        })
        .catch((msg) => {
          self.dialog = false;
          this.show_loader(false);
          this.swall_it("Error Saveing", msg, "error", null, null);
        });
    },
    init() {
      this.get_aft_act_reps();
    },
  },

  mounted() {
    this.$nextTick(() => {
      let lstlinks = document.querySelectorAll(".menulink");
      console.log("lengths: " + lstlinks.length);
      for (let index = 0; index < lstlinks.length; index++) {
        const element = lstlinks[index];
        element.style.color = "white";
      }
      document.getElementById("aactionlink").style.color = "orange";
      this.init();
    });
  },
};
</script>
